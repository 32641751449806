import React from 'react';
import { Parallax, Background } from 'react-parallax';
import styled from 'styled-components';
import '../../components/sliderStyles.css';
import { MainSection } from './sections/Main';
import parallax from '../../../public/images/parallax.svg';
import { Grid } from '../../components/Grid';
import { MESSAGE_TITLE, SEARCH_LINK, SECTION } from '../../constants';

const Header = React.lazy(() => import('../../components/Header'));
const Footer = React.lazy(() => import('../../components/Footer'));
const InfoSection = React.lazy(() => import('./sections/Info'));
const CapabilitySection = React.lazy(() => import('./sections/Capability'));
const ToolsSection = React.lazy(() => import('./sections/Tools'));
const BenefitsSection = React.lazy(() => import('./sections/Benefits'));
const ContactsSection = React.lazy(() => import('../../components/Contacts'));

const ParallaxImage = styled(Background)`
  height: 789px;
  width: 1743px;
  background: url('${parallax}') no-repeat;
`;

function App(): JSX.Element {
  return (
    <Grid.Container>
      <Parallax strength={2500}>
        <ParallaxImage />
        <Header
          title='Перейти в istock.info'
          text='Умная база данных стандартов'
          buttonLink={SEARCH_LINK}
          id='gtm_block_0'
        />
        <Grid.Main>
          <Grid.FlexibleWrapper>
            <MainSection id='gtm_block_1' />
            <InfoSection id='gtm_block_2' />
            <CapabilitySection id='gtm_block_3' />
            <ToolsSection id='gtm_block_4' />
            <BenefitsSection id='gtm_block_5' />
            <ContactsSection
              section={SECTION.FOR_ENGINEER}
              title={MESSAGE_TITLE.FOR_ENGINEER}
              id='gtm_block_6'
            />
          </Grid.FlexibleWrapper>
        </Grid.Main>
      </Parallax>
      <Footer />
    </Grid.Container>
  );
}

export { App };
