import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Heading } from '../../../UI';
import borderImage from '../../../../public/images/main-section/border.svg';
import infoImage from '../../../../public/images/main-section/info.png';
import { ReactComponent as ScanIcon } from '../../../../public/icons/scan.svg';
import { ReactComponent as SearchIcon } from '../../../../public/icons/search.svg';
import { SEARCH_LINK } from '../../../constants';

const MainInfo = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    flex-direction: column;

    ${mediaQueries.s} {
      flex-direction: column-reverse;
      margin-bottom: 16px;
    }
  `,
);
const ImageBlock = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    margin-top: -26px;
    margin-bottom: 15px;
    text-align: center;

    ${mediaQueries.s} {
      margin-top: 0;
    }
  `,
);

const BadgeContainer = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -40%);

    ${mediaQueries.s} {
      display: none;
    }
  `,
);

const Hint = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    position: absolute;
    right: 0;
    width: 480px;
    padding: 28px;
    margin-top: 4px;
    background: ${colors.primary.main};
    color: ${colors.white};
    box-shadow: 0px 14px 24px rgba(62, 67, 104, 0.05);
    border-radius: 8px;
    text-align: left;
    opacity: 0;
    transition: 0.2s ease-in-out opacity;

    ${mediaQueries.m} {
      right: -210px;
    }
  `,
);

const Badge = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${colors.primary.main};
    box-shadow: 0px 4px 11px -10px rgba(77, 124, 254, 0.2),
      0px 16px 28px -6px rgba(77, 124, 254, 0.29);

    &:hover + ${Hint} {
      opacity: 1;
    }
  `,
);

const Search = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    margin-bottom: 150px;
    padding: 16px 28px 28px;
    background: ${colors.highlight[1]};
    border-radius: 16px;
    text-align: center;

    ${mediaQueries.s} {
      padding: 16px;
      margin-bottom: 50px;
    }
  `,
);

const TextInput = styled.input(
  ({ theme: { colors, mediaQueries } }) => css`
    width: 100%;
    background: ${colors.white};
    color: ${colors.text.primary};
    padding: 0 120px 0 16px;
    height: 54px;
    line-height: 54px;
    box-shadow: 0px 14px 24px rgba(62, 67, 104, 0.05);
    border-radius: 8px;
    text-overflow: ellipsis;

    ${mediaQueries.s} {
      padding-right: 62px;
    }
  `,
);

const InputWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;

    svg {
      display: none;

      ${mediaQueries.s} {
        display: block;
      }
    }

    & ${Button} {
      position: absolute;
      right: 4px;
      top: 4px;

      ${mediaQueries.s} {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 46px;
        height: 46px;
        font-size: 0px;
        color: transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 20px;
      right: -176px;
      width: 172px;
      height: 306px;
      background: url('${borderImage}');

      ${mediaQueries.l} {
        display: none;
      }
    }
  `,
);

const SLOT_ITEM_HEIGHT = 58;
const SLOT_ITEM_HEIGHT_M = 44;
const SLOT_ITEM_HEIGHT_XS = 30;

const SlotItem = styled.ul<{ animation: boolean; itemsCount: number }>(
  ({ theme: { mediaQueries }, animation, itemsCount }) => css`
    position: relative;
    top: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    transition: 1.2s ease-in top;

    ${animation &&
    css`
      top: -${(itemsCount - 1) * SLOT_ITEM_HEIGHT}px;

      & > li {
        height: ${SLOT_ITEM_HEIGHT}px;
      }

      ${mediaQueries.m} {
        top: -${(itemsCount - 1) * SLOT_ITEM_HEIGHT_M}px;

        & > li {
          height: ${SLOT_ITEM_HEIGHT_M}px;
        }
      }

      ${mediaQueries.xs} {
        top: -${(itemsCount - 1) * SLOT_ITEM_HEIGHT_XS}px;

        & > li {
          height: ${SLOT_ITEM_HEIGHT_XS}px;
        }
      }
    `}
  `,
);

const ContainerText = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    display: inline-flex;
    height: ${SLOT_ITEM_HEIGHT}px;
    color: ${colors.primary.main};
    overflow: hidden;

    & ${SlotItem}:nth-child(3n) {
      margin-left: 8px;
    }

    ${mediaQueries.m} {
      height: ${SLOT_ITEM_HEIGHT_M}px;
    }

    ${mediaQueries.xs} {
      height: ${SLOT_ITEM_HEIGHT_XS}px;
    }
  `,
);

function Slot({ animation, items }: { animation: boolean; items: number[] }) {
  return (
    <SlotItem animation={animation} itemsCount={items.length}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`${index}_${item}`}>{item}</li>
      ))}
    </SlotItem>
  );
}

function SlotMachineText({ animation }: { animation: boolean }) {
  return (
    <ContainerText>
      <Slot animation={animation} items={[1, 2]} />
      <Slot animation={animation} items={[8, 9, 0]} />
      <Slot animation={animation} items={[5, 6, 7, 8, 9, 0]} />
      <Slot animation={animation} items={[6, 7, 8, 9, 0]} />
      <Slot animation={animation} items={[8, 9, 0]} />
      <Slot animation={animation} items={[8, 9, 0]} />
      <Slot animation={animation} items={[9, 0]} />
      <Slot animation={animation} items={[2, 3, 4, 5, 6, 7, 8, 9, 0]} />
    </ContainerText>
  );
}

function MainSection({ id }: ISection) {
  const [inputValue, setInputValue] = useState<string>('');
  const [animation, setAnimation] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setAnimation(true), 1000);
  }, []);

  return (
    <>
      <MainInfo id={id}>
        <Heading mt={9}>
          <SlotMachineText animation={animation} /> товаров по <br />
          ГОСТ и DIN оцифрованы <br />
          для вашей работы
        </Heading>
        <ImageBlock>
          <img src={infoImage} alt='' />
          <BadgeContainer>
            <Badge>
              <ScanIcon />
            </Badge>
            <Hint>
              В <strong>istock.info</strong> cтандарты оцифрованы в удобные
              карточки. Бумажные носители больше не нужны.
            </Hint>
          </BadgeContainer>
        </ImageBlock>
      </MainInfo>
      <Search>
        <Heading as='h5' mb={16}>
          Найдите товар по стандарту в istock.info
        </Heading>
        <InputWrapper>
          <TextInput
            type='text'
            placeholder='Подшипник роликовый радиальный ГОСТ 8328-75'
            value={inputValue}
            onChange={event => setInputValue(event.target.value)}
          />
          <Button
            as='a'
            href={`${SEARCH_LINK}?search=${
              inputValue !== ''
                ? inputValue
                : 'Подшипник роликовый радиальный ГОСТ 8328-75'
            }`}
            target='_blank'
            rel='noreferrer'
          >
            <SearchIcon /> Найти
          </Button>
        </InputWrapper>
      </Search>
    </>
  );
}

export { MainSection };
